import React from 'react'

import ActionBtn from '../ActionBtn'
import { brandIco } from './SoftwareReducer'


export function CardTopLine({ props:{sw, car, isAdmin, isCardEdit, setIsCardEdit} }) {

  const progIcon = `https://bzdrive.com/files/ico/Prog ${sw?.programmer}.png`
  const alt = `${sw?.programmer}`

  const title = `${sw?.ECUType?.length ? sw.ECUType :  "ECUType"}`

  return (
    <div className="CardTopLine flex">

      <div className="Title flex start bold">
        {/* <img className="IcoColor flex" src={brandIco(car?.brand)} alt="SVG" /> */}
        <img className="IcoProgrammer flex" src={progIcon} alt={alt} />
        <span>{title}</span>
      </div>

      <div className="AdminBtns flex end wrap">

        <ActionBtn props={{ name: `cancel`, click:()=>setIsCardEdit(prev=>false) }} />

        {
          sw?.id &&
          <div className="CardID txtGry flex end">
            <span>{`ID: ${sw?.id ?? ""}`}</span>
          </div>
        }

      </div>

    </div>
  )
}
