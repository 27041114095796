import React from 'react'

import FileUpload from '../FileUpload'


export function Sticker({ props:{id, isAdmin, sw, s, setSoft, link, defaultFileAddr, setSave} }) {

  const allowedMimeTypes = ["image/jpeg"]
  const defaultFileName = `Sticker_${Date.now()}.jpg`

  function SET_STICKER(res){
    setSoft( (prev)=> prev.map( (soft, i)=> i !== s ? soft : {...soft, ECUsticker: res?.data} ) )
    setSave(true)
  }

  return (
    <div className="Sticker flex column start">

      {
        sw?.ECUsticker
        ?
        <img className="StickerImg flex" src={`${link}/${defaultFileAddr}/${sw?.ECUsticker?.name}`} alt="label" />
        :
        <React.Fragment>
          <span className="CardLine NoSticker flex bold">{`ECU sticker`}</span>
          <span className="CardLine StickerLine flex start bold">{`ECU: ${sw?.ECUType ?? ""}`}</span>
          <span className="CardLine StickerLine flex start bold">{`SW: ${sw?.swVersion ?? ""}`}</span>
          <span className="CardLine StickerLine flex start bold">{`HW: ${sw?.hwVersion ?? ""}`}</span>
        </React.Fragment>
      }

      {
        isAdmin && id !== "new" &&
        <div className='StickerDownload flex end'>
          <FileUpload props={{ defaultFileAddr, defaultFileName, allowedMimeTypes, cb:SET_STICKER}} />
        </div>
      }
      
    </div>
  )
}
