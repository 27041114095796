import React from 'react'

import { bzBytesCalc } from '../../../AppFunctions'
import InputText from '../InputText'
import FileUpload from '../FileUpload'
import { SoftPricePropses } from './SoftPropses'


export function SoftwarePannel({ props:{id, isAdmin, sw, s, setSoft, link, defaultFileAddr, setSave} }) {

  function SET_SOFTWARE_FILES(res){
    setSoft( (prev)=> prev.map( (soft, i)=> i !== s ? soft : {...soft, files: soft?.files ? [...soft?.files, res?.data] : [res?.data]} ) )
    setSave(true)
  }

  return (
    <div className="SoftwarePannel flex column start">

      <div className="SoftWareTopLine flex start bold">
        Pliki
      </div>

      {
        sw?.files?.length &&
        <div className="SoftWareLines flex column start">
        {
          sw.files.map( (soft, n)=>{

            const key = `SoftWareLine${n}`
            const href = `${link}/${defaultFileAddr}/${soft?.name}`
            const size = `${bzBytesCalc(soft?.size)?.num} ${bzBytesCalc(soft?.size)?.unit}`
            const pricePropses = SoftPricePropses( s, soft, n, setSoft, setSave )

            return(
              <div className="SoftwareLine flex" key={key}>

                <a className="FileName flex start" href={href} target="_blank" rel="noopener noreferrer">
                  {soft?.name}
                </a>

                <span className="FileSize flex end">{size}</span>

              </div>
            )
          })
        }
        </div>
      }
      
      { isAdmin && id !== "new" && <FileUpload props={{defaultFileAddr, cb:SET_SOFTWARE_FILES}} /> }
      
    </div>
  )
}
