import React, { useState } from "react"

import ActionBtn from "../../../../All/ActionBtn"
import SoftwareCard from "../../../../All/SoftwareCard"
import { getFileLinkAddr } from "../../../../All/SoftwareCard/SoftwareReducer"


export function SoftwareLine({ props:{nr, car, setCar, setSoft, sw, s, save, setSave, Reducer} }){

  const [isCardEdit, setIsCardEdit] = useState(false)
  const [more, setMore] = useState(false)
  const [del, setDel] = useState(false)

  const {link, defaultFileAddr} = getFileLinkAddr(nr, sw?.id)

  const morePropses = { name:"more", click:()=>{ setDel(false); setMore(prev=>!prev) } }
  const editPropses = { name:`edit`, click:()=>{ setMore(prev=>!prev); setIsCardEdit(prev => !prev) } }
  const deletePropses = { name:"delete", click:()=>setDel(true) }
  const cancelPropses = { name:"cancel", click:()=>{ setDel(false); setMore(prev=>!prev) } }
  const checkPropses = { name:"check", click:()=>{
    Reducer({type:`DELETE_SOFTWARE_FOLDER`, swID:sw?.id, folderAddr:defaultFileAddr, cb:(data)=>{
      if(data?.status === 200){
        setSoft(prev=> prev.filter( el=> el?.id !== sw?.id ))
        setSave(prev=> true)
      }
    }})
  }}

  // Funkcja do przesuwania elementu w liście
  const moveSoftware = (from, to) => {
    setSave(true)
    setSoft(prev => {
      const itemToMove = prev[from]
      const remainingItems = prev.filter((_, index) => index !== from)
      const before = [...remainingItems.slice(0, to)]
      const after = [...remainingItems.slice(to)]
      return [...before, itemToMove, ...after]
    })
  }

  // Funkcje przeciągania
  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', s) // 's' reprezentuje indeks software
    e.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }

  const handleDrop = (e) => {
    e.preventDefault()
    const fromIndex = parseInt(e.dataTransfer.getData('text/plain'), 10)
    const toIndex = s
    if(fromIndex !== toIndex){ moveSoftware(fromIndex, toIndex) }
  }

  const progIcon = `https://bzdrive.com/files/ico/Prog ${sw?.programmer}.png`
  const alt = `${sw?.programmer}`

  return (
    <div
      className="SoftwareLine flex"
      key={`SoftwareID-${sw?.id}`}
      // draggable={!printMode}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {!isCardEdit ? (
        <React.Fragment>
          <span className="Icon Line flex start"><img className="ImgBtn" src={progIcon} alt={alt} /></span>
          <span className="Prog flex start">{sw?.programmer}</span>
          <span className="Type flex start">{sw?.ECUType}</span>
          <span className="Method flex start">{sw?.readMethod}</span>
          <span className="SwType flex start">{sw?.swType}</span>
          <span className="Mod flex start">{sw?.mod}</span>
          <span className="ActBtns flex end">
            { !more && <span className="LineID flex start">{sw?.id}</span> }
            { more && !del && <ActionBtn props={editPropses} /> }
            { more && !del && <ActionBtn props={deletePropses} /> }
            { more && del && <ActionBtn props={checkPropses} /> }
            { more && del && <ActionBtn props={cancelPropses} /> }
            <ActionBtn props={morePropses} />
          </span>

        </React.Fragment>
      ) : (
        <SoftwareCard props={{car, setCar, sw, s, setSoft, link, defaultFileAddr, isCardEdit, setIsCardEdit, save, setSave}} />
      )}
    </div>
  )
}
